<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Create rotation"
    description="Create new rotation box for Default rotations group."
    :disabled="loading"
    primary-button-text="Create group"
    secondary-button-text="Cancel"
    @update:model-value="$emit('update:modelValue', false)"
    @confirm="submit"
  >
    <form class="h-full" @submit.prevent>
      <UiInputSelect
        v-model="data.language_id"
        :items="languages"
        label="Language"
        name="language"
        compact
        placeholder="Choose a Language"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['language_id']) || errorMessage"
        @update:model-value="errorMessage = ''"
      />
      <UiInputSelect
        v-model="data.user_ids"
        :items="users"
        label="Users"
        multiple
        group
        avatar
        compact
        verified
        name="Agents"
        placeholder="Add users"
        :error="useGetFieldErrors(v$, ['user_ids'])"
      />
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

import type { InputItem, Rotation } from '@/types'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const errorMessage = ref('')
const loading = ref(false)
const languages = ref<InputItem[]>([])
const users = ref<InputItem[]>([])

onNuxtReady(() => Promise.all([getLanguages(), getAgents()]))

const getAgents = async () => {
  users.value = await useGetUsersItems(true, false, true)
}

const getLanguages = async () => {
  const languagesList = await useLanguages()
  languages.value = languagesList.map((l) => ({ text: l.name, value: l.id }))
}

const data = ref<Rotation>({
  language_id: null,
  user_ids: [],
})

const rules = computed(() => ({
  language_id: { required: helpers.withMessage('The language is required', required) },
  user_ids: { required: helpers.withMessage('Users are required', required) },
}))

const v$ = useVuelidate(rules, data)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (isValid) {
    loading.value = true
    try {
      const newRotation = await useCreateDefaultRotation({ ...data.value })
      emits('input', newRotation)
      emits('update:modelValue', false)
    } catch (error: any) {
      errorMessage.value = error.message
    } finally {
      loading.value = false
    }
  }
}
</script>

<style scoped></style>
